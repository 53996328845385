export const CLIENT_CREDENTIAL_REQUEST = "CLIENT_CREDENTIAL_REQUEST";
export const CLIENT_CREDENTIAL_SUCCESS = "CLIENT_CREDENTIAL_SUCCESS";
export const CLIENT_CREDENTIAL_FAILURE = "CLIENT_CREDENTIAL_FAILURE";
export const CLIENT_CREDENTIAL_TYPE = "CLIENT_CREDENTIAL";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_TYPE = "LOGIN_CREDENTIAL";
export const LOGOUT = "LOGOUT";
export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const USER_LOGOUT = "USER_LOGOUT";
export const USER_LAST_ACCESS = "USER_LAST_ACCESS";
export const MACHINE_ID = "MACHINE_ID";
export const LOGIN_REQUEST_RULES = {
  username: {
    required: true,
  },
  password: {
    required: true,
  }, 
};
export const CLIENT_CREDENTIAL_VALUES = {
  username: "webapp",
  password: "P@sswordWEPAPP"
};
  