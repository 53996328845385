import { combineLocale } from "@/helpers";

// Layout
import { Km as ListLayoutKm, En as ListLayoutEn } from "./list-layout";
import { Km as EditLayoutKm, En as EditLayoutEn } from "./edit-layout";
import { Km as DetailLayoutKm, En as DetailLayoutEn } from "./detail-layout";
import { Km as CreateLayoutKm, En as CreateLayoutEn } from "./create-layout";
import { Km as FooterKm, En as FooterLayoutEn } from "./footer";

// User Account
import { Km as RoleKm, En as RoleEn } from "./modules/role";
import { Km as DashboardKm, En as DashboardEn } from "./modules/dashboard";
import { Km as UserAccountKm, En as UserAccountEn } from "./modules/user-account";

const locale = {
  en: combineLocale({
    footer: FooterLayoutEn,
    listLayout: ListLayoutEn,
    editLayout: EditLayoutEn,
    detailLayout: DetailLayoutEn,
    createLayout: CreateLayoutEn,
    UserAccount: UserAccountEn,
    Role: RoleEn,
    dashboard: DashboardEn,
  }),
  km: combineLocale({
    footer: FooterKm,
    listLayout: ListLayoutKm,
    editLayout: EditLayoutKm,
    detailLayout: DetailLayoutKm,
    createLayout: CreateLayoutKm,
    UserAccount: UserAccountKm,
    Role: RoleKm,
    dashboard: DashboardKm,
  })
};

export default locale;