// Libs
import React, { Fragment } from "react";
import { Form } from "antd";
import Media from "react-media";
import PropTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

// Components
import CollapsePanel from "../collapse-panel";
import AsyncTextBox from "../async-text-box";
import AsyncCheckBox from "../async-check-box";
import AsyncClearFilter from "../async-clear-filter";
import AsyncSearchButton from "../async-search-button";

const FormItem = Form.Item;

const WrapperForm = styled.div`
  .ant-form.ant-form-inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: unset;
    padding: 10px;

    @media (max-width: 1024px) {
      display: ${props => props.hasChildren ? "block" : "flex"};
      justify-content: ${props => props.hasChildren ? "unset" : "space-between"};
    }
  }

  .ant-calendar-picker, .ant-input-affix-wrapper {
    width: 150px !important;
  }
  .ant-date-range-picker {
    width: 250px !important;
  }

  .ant-form-item {
    margin-right: 5px !important;
  }
`;

const WrapperChildren = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  @media (max-width: 550px) {
    width: ${props => props.hasChildren ? "auto" : "100%"};
  }

  .ant-row.ant-form-item {
    width: 100%;
    padding-right: 0.5rem;
    margin-right: 0rem !important;

    @media (max-width: 550px) {
      padding-right: 0rem !important;
    }

    @media (min-width: 550px) and (max-width: 768px) {
      :nth-child(3n+3) {
        padding-right: 0rem !important;
      }
      width: ${props => props.hasChildren ? "33.3%" : "100%"};
    }

    @media (min-width: 770px) and (max-width: 1024px) {
      :nth-child(4n+4) {
        padding-right: 0rem !important;
      }
      width: ${props => props.hasChildren ? "25%" : "100%"};
    }
    
    .ant-select,
    .ant-calendar-picker,
    .ant-input-affix-wrapper,
    .ant-form-item-control-wrapper {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
`;

const WrapperExtraFilterAndClearFilter = styled.div`
  display: ${props => props.hasExtraFilter ? "block" : "flex"};
  margin-top: 8px !important;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 550px) {
    width: ${props => !props.hasChildren && "100%"};
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .wrapper-extra-filter-and-archive {
    display: flex;

    @media (max-width: 550px) {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .ant-row.ant-form-item {
        margin: 0rem !important;
      }
    }
  }

  .wrapper-clear-filter {
    @media (max-width: 550px) {
      .ant-row.ant-form-item {
        display: block;
        margin: 0rem !important;
        button {
          width: 100%;
        }
      }
    }
  }
`;

function FilterBox(props) {

  const { formatMessage } = useIntl();

  const {
    loading,
    children,
    onReset,
    filterKeys,
    enableText,
    extraFilter,
    textBoxMinWidth,
    enableArchiveFilter,
    location: { search },
  } = props;
  const hasChildren = children && children.length > 0 || typeof(children) == "object" ? true : false;
  return (
    <Media query={{ maxWidth: 1024 }}>
      {matches => matches ? (
        <Fragment>
          <CollapsePanel
            title="Filter"
            className="border rounded mb-2"
            activeKey={search ? "1" : "0"}
          >
            <WrapperForm
              hasChildren={hasChildren}
              className="wrapper-filter-box"
            >
              <Form layout="inline">
                <WrapperChildren hasChildren={hasChildren}>
                  {children}
                  {enableText && (
                    <FormItem>
                      <AsyncTextBox 
                        name="query" 
                        placeholder={formatMessage({id: "listLayout.containText"})}
                      />
                    </FormItem>
                  )}
                </WrapperChildren>
                <WrapperExtraFilterAndClearFilter
                  hasChildren={hasChildren}
                  hasExtraFilter={extraFilter}
                >
                  <div className="wrapper-extra-filter-and-archive">
                    {extraFilter}
                    {enableArchiveFilter && (
                      <FormItem>
                        <AsyncCheckBox
                          name="archive"
                          label={<FormattedMessage id="listLayout.archived" />}
                        />
                      </FormItem>
                    )}
                  </div>
                  <div className="wrapper-clear-filter">
                    <FormItem>
                      <AsyncSearchButton
                        loading={loading}
                        filterKeys={filterKeys}
                      />
                    </FormItem>
                  </div>
                  <div className="wrapper-clear-filter">
                    <FormItem>
                      <AsyncClearFilter
                        onReset
                        loading={loading}
                        filterKeys={filterKeys}
                      />
                    </FormItem>
                  </div>
                </WrapperExtraFilterAndClearFilter>
              </Form>
            </WrapperForm>
          </CollapsePanel>
        </Fragment>
      ) : (
        <Fragment>
          <WrapperForm className="wrapper-filter-box">
            <Form layout="inline">
              {children}
              {enableText && (
                <FormItem>
                  <AsyncTextBox 
                    minWidth={textBoxMinWidth}
                    name="query"
                    placeholder={formatMessage({id: "listLayout.containText"})}
                  />
                </FormItem>
              )}
              {extraFilter}
              {enableArchiveFilter && (
                <FormItem>
                  <AsyncCheckBox
                    name="archive"
                    label={<FormattedMessage id="listLayout.archived" />}
                  />
                </FormItem>
              )}
              <FormItem>
                <AsyncSearchButton
                  loading={loading}
                  filterKeys={filterKeys}
                />
              </FormItem>
              <FormItem>
                <AsyncClearFilter
                  loading={loading}
                  onReset={onReset}
                  filterKeys={filterKeys}
                />
              </FormItem>
            </Form>
          </WrapperForm>
        </Fragment>
      )
      }
    </Media>
  );
}

FilterBox.propTypes = {
  children: PropTypes.node,
  enableText: PropTypes.bool,
  extraFilter: PropTypes.any,
  filterKeys: PropTypes.array,
  onReset: PropTypes.func,
  loading: PropTypes.bool,
  location: PropTypes.object,
  textBoxMinWidth: PropTypes.object,
  getLocalizedString: PropTypes.func,
  enableArchiveFilter: PropTypes.bool,
};

FilterBox.defaultProps = {
  enableText: true,
  enableArchiveFilter: true,
};

export default withRouter(connect()(FilterBox));