import * as Constants from "@/constants/entity";
import merge from "lodash/fp/merge";

const initialState = {
  items: {},
};

export default function(state = initialState, action) {
  switch (action.type) {
  case Constants.MERGE_ENTITY:
    return mergeEntity(state, action.entityName, action.key, action.entity);
  case Constants.MERGE_ENTITIES:
    return mergeEntities(state, action.entities);
  case Constants.SYNC_ENTITIES:
    return syncEntities(state, action.entity, action.key, action.idAttribute);
  case Constants.SYNC_ARRAY_ENTITIES:
    return syncArrayEntities(state, action.entity, action.key);
  case Constants.DELETE_ENTITY:
    return deleteEntity(state, action.entityId, action.entityName);
  }
  return state;
}

function mergeEntity(state, entityName, key, entity) {
  return {
    ...state,
    [entityName]: {
      ...state[entityName],
      [key]: entity
    }
  };
}

function mergeEntities(state, entities) {
  return merge(state, entities);
}

function syncEntities(state, entity, key, idAttribute) {
  const idName = idAttribute || "id";
  return { ...state, [key]: { ...state[key], [entity[idName]]: entity } };
}

function syncArrayEntities(state, entity, key) {
  return { ...state, [key]: entity };
}

function deleteEntity(state, entityId, entityName) {
  const entities = state[entityName];
  delete entities[entityId];
  return {
    ...state,
    [entityName]: {
      ...entities
    }  
  };
}