import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// Components
import ItemList from "./item-list";
import NotFound from "@/components/not-found";
import Loading from "@/components/loading";
import { 
  CLIENT_CREDENTIAL_TYPE,
  CLIENT_CREDENTIAL_REQUEST, 
  CLIENT_CREDENTIAL_VALUES,
} from "@/constants/authentication";

// Utils
import localization from "@/localization";
import { authenticateInit } from "@/actions/authentication";

function View() {

  const [isActive, setIsActive] = React.useState(false);
  const dispatch = useDispatch();
  const localClientCredential = localStorage.getItem(CLIENT_CREDENTIAL_TYPE);

  const onInitialized = () => {
    setIsActive(true);
  };

  useEffect(() => {
    dispatch(authenticateInit(CLIENT_CREDENTIAL_VALUES, onInitialized));
    handleListenerWhenUserClearLocalStrorage();
    return () => {
      window.removeEventListener("storage", onHandleAddEventListener);
    };
  }, [localClientCredential]);

  const onHandleAddEventListener = () => {
    const localClientCredential = localStorage.getItem(CLIENT_CREDENTIAL_TYPE);
    if (!localClientCredential) {
      window.location.reload();
    }
  };

  const handleListenerWhenUserClearLocalStrorage = () => {
    window.addEventListener("storage", onHandleAddEventListener);
  };

  const loading = useSelector(state => state.request[CLIENT_CREDENTIAL_REQUEST]);
  const locale = useSelector((state) => state.locale.locale);
  return (
    <BrowserRouter>
      <IntlProvider 
        locale={locale}
        messages={localization[locale]}
      >
        {((loading == true) || (isActive == false)) ? (
          <Loading  
            header="Loading..."
            text="Please Wait" />
        ) : (
          <Switch>
            <Redirect
              exact
              from="/"
              to="/item"
            />
            <Route 
              path="/item" 
              component={ItemList} 
            />
            <Route component={NotFound} />
          </Switch>
        )}
      </IntlProvider>
    </BrowserRouter>
  );
}

export default View;