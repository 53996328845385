// Libs
import React from "react";
import { Form } from "antd";

// Components
import FilterComponent from "@components/filter-box";
import AsyncCheckBox from "@/components/async-check-box";

function FilterBox(props) {

  return (
    <FilterComponent 
      enableArchiveFilter={false}
      extraFilter={
        <Form.Item>
          <AsyncCheckBox
            name="includeOutOfStock"
            label="Include Out of Stock"
          />
        </Form.Item>
      }
      {...props}>
    </FilterComponent>
  );
}

export default FilterBox;