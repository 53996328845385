import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Card, Typography, Button  } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

// helpers
import { getBreadcrumbPageLabel } from "@/helpers";

// component
import Breadcrumbs from "@components/breadcrumbs";

const Title = Typography.Title;

function Header(props) {

  const { 
    path, 
    title, 
    creatable, 
    breadcrumbs, 
    extraButtons, 
  } = props;
  return (
    <Card 
      bordered={false} 
      bodyStyle={{padding: "16px"}}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {breadcrumbs == null ? (
            <Title level={4} className="mb-0 khmer-moul">
              {getBreadcrumbPageLabel(title)}
            </Title>
          ) : (
            <Breadcrumbs 
              breadcrumbs={breadcrumbs}
            />
          )}
        </div>
        <div className="d-flex align-items-center">
          {creatable && (
            <Fragment>
              <Link to={`${path}/create`}>
                <Button 
                  ghost 
                  type="primary" 
                  icon={<PlusCircleOutlined  />}
                >
                  <span className="ml-1">
                    <FormattedMessage id="listLayout.create" />
                  </span>
                </Button>
              </Link>
            </Fragment>
          )}
          {extraButtons}
        </div>
      </div>
    </Card>
  );
}

Header.propTypes = {
  path: PropTypes.string,
  title: PropTypes.string,
  creatable: PropTypes.bool,
  extraButtons: PropTypes.any,
  breadcrumbs: PropTypes.any,
};

export default Header;