// Utils
import { ItemArraySchema } from "@/schemas/item";
import { createListAction } from "@/utils/action-creator";
import * as service from "@/services/item";
import * as Constants from "@/constants/item";

export const getItemList = (values) => (dispatch, getState) => {
  const options = {
    serviceMethod: service.getItem,
    schema: ItemArraySchema,
    requestName: Constants.ITEM_LIST_REQUEST,
    dispatch,
    getState
  };
  return createListAction(options, values);
};

