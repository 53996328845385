import React from "react";
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Pagination, Typography } from "antd";
import { SyncOutlined } from "@ant-design/icons";

// Constants
import * as Constants from "@/constants/layout";

function Counter(props) {
  
  const {
    onClick,
    loading,
    className,
    pagination: {
      limit,
      offset,
      total,
      count,
    },
    handlePaginationChange,
    handlePageSizeChange,
    defaultCurrent,
    dataSource,
    selected,
  } = props;
  return (
    <div className={`d-flex justify-content-between align-items-center ${className}`}>
      <div className="d-flex align-items-center">
        <Typography.Text>
          {dataSource.length == 0 ? 0 : offset + 1 || 0} - {count || 0} <FormattedMessage id="listLayout.of" /> {total || 0} 
          <SyncOutlined  
            spin={loading} 
            className="ml-1"
            onClick={onClick} 
          />
          {selected > 0 && (
            <span style={{ marginLeft: 8 }}>
              Selected {selected} {selected > 1 ? "Items" : "Item"}
            </span>
          )}
        </Typography.Text>
      </div>
      <div className="d-flex align-items-center">
        <Pagination 
          size="small" 
          showSizeChanger 
          showQuickJumper
          total={total} 
          pageSize={limit}
          current={defaultCurrent}
          pageSizeOptions={Constants.LIST_LAYOUT_PAGE_SIZE}
          onChange={handlePaginationChange}
          onShowSizeChange={handlePageSizeChange}
        />
      </div>
    </div>
  );
}

Counter.propTypes = {
  spin: PropTypes.bool,
  className: PropTypes.string,
  defaultCurrent: PropTypes.number,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  pagination: PropTypes.shape({
    limit: PropTypes.number,
    offset: PropTypes.number,
    count: PropTypes.number,
    total: PropTypes.number
  }),
  handlePaginationChange: PropTypes.func,
  handlePageSizeChange: PropTypes.func,
  dataSource: PropTypes.array,
  selected: PropTypes.number,
};

Counter.defaultProps = {
  pagination: {
    limit: 0,
    offset: 0,
    count: 0,
    total: 0,
  },
};

export default Counter;