import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import classnames from "classnames";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { ClearOutlined } from "@ant-design/icons";

// Helper
import { 
  getQueryParamUrl, 
  updateQueryParamUrl, 
  getQueryParamFromLocalStorage, 
  updateQueryParamToLocalStorage, 
} from "@/helpers";

function AsyncClearFilter(props) {

  const onClear = () => {
    const { filterKeys, onReset, queryStorage, history } = props;
    let query = getQueryParamUrl();
    const currentActiveFilter = localStorage.getItem("FILTER_ACTIVE");
    const filterValues = localStorage.getItem(currentActiveFilter);
    const localStorageValueToJson = JSON.parse(filterValues);
    filterKeys && filterKeys.map(key => {
      delete query[key];
      if (queryStorage != "url") {
        delete localStorageValueToJson[key];
      }
    });
    updateQueryParamUrl(query, history);
    if (queryStorage != "url") {
      updateQueryParamToLocalStorage(localStorageValueToJson);
    }
    if (onReset) {
      onReset();
    }
  };

  const { filterKeys, loading, queryStorage } = props;
  const query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
  let clearable = false;
  filterKeys && filterKeys.map((key) => {
    const value = query[key];
    if (value) {
      clearable = true;
    }
  });
  const type = classnames(clearable ? "primary" : "default");
  return (
    <Button 
      ghost
      type={type}
      onClick={onClear}
      loading={loading}
      icon={<ClearOutlined />}
      disabled={loading || !clearable}
    >
      <span className="ml-1">
        {loading ? <FormattedMessage id="listLayout.loading" /> : <FormattedMessage id="listLayout.clearFilter" />}
      </span>
    </Button>
  );
}

AsyncClearFilter.propTypes = {
  loading: PropTypes.bool,
  onReset: PropTypes.func,
  history: PropTypes.object,
  filterKeys: PropTypes.array,
  queryStorage: PropTypes.string,
};

AsyncClearFilter.defaultProps = {
  queryStorage: "local",
};

export default withRouter(AsyncClearFilter);