import { SubmissionError } from "redux-form";
import React from "react";
import _ from "lodash";
import * as QueryString from "query-string";
import moment from "moment";
import { Modal, Tooltip } from "antd";
import * as DefaultConstants from "@/constants/default"; 
import * as ListLayoutConstants from "@/constants/layout";

const confirm = Modal.confirm;

export const isValidEmail = (email) => {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export const removeEmptyObjectFromArray = (array) => {
  if (array) {
    return array.filter(value => Object.keys(value).length !== 0);
  }
  return array;
};

export const validate = (rules, values) => {
  var errors = {};
  var isError = false;
  for (var field in rules) {
    var rule = rules[field];
    var value = rule["value"];
    if (values != null) {
      value = values[field];
    }
    var type = rule["type"];
  
    if (rule["required"] == true && (value == null || typeof value == "string" && value.trim() == "")) {
      errors[field] = rule["message"] || "This field is required";
      isError = true;
    }
  
    if (rule["required"] == true && rule.type == "array" && (value == null || value.length == 0)) {
      errors[field] = { _error: rule["message"] || "Please select at least 1 of these" };
      isError = true;
    }
      
    if (rule.validation && rule.type == "array" && value && value.length > 0) {
      const validation = rule.validation(value);
      if (validation) {
        errors[field] = { _error: validation.message };
        isError = true;
      }
    }
  
    if (type == "password" && value != null && value.length < 6) {
      errors[field] = rule["message"] || "Password must be 6 characters or more";
      isError = true;
    }
  
    if (Object.prototype.hasOwnProperty.call(rule, "equalsTo") && value != null) {
      var equalsTo = rule["equalsTo"];
      if (equalsTo && equalsTo.value != value) {
        const message = equalsTo.message || `${field} and ${equalsTo.name} do not match!`;
        errors[field] = message;
        errors[equalsTo.name] = message;
        isError = true;
      }
    }
  
    if (type == "email") {
      if (rule["required"] == true) {
        if (value != "" && value !== null && isValidEmail(value)) {
          errors[field] = rule["message"] || "Invalid email address";
          isError = true;
        } else if (value == null && value == "") {
          errors[field] = rule["message"] || "This field is required";
          isError = true;
        }
      } else {
        if (value != null && value != "" && isValidEmail(value)) {
          errors[field] = rule["message"] || "Invalid email address";
          isError = true;
        }
      }
    }
  
    if (type == "object") {
      const { ...subRules } = rule;
      const result = validate(subRules, values[field]);
      errors[field] = result;
      if (errors[field] != null) {
        isError = true;
      }
    }
  
    if (Object.prototype.hasOwnProperty.call(rule, "compare") && value != null) {
      var compare = rule["compare"];
      if (compare && compare.value > value) {
        const message = compare.message || `${compare.name} can not greater than ${field} !`;
        errors[field] = message;
        errors[compare.name] = message;
        isError = true;
      }
      if (compare && compare.condition) { 
        if (compare.condition) {
          const message = compare.message;
          errors[field] = message;
          errors[compare.name] = message;
          isError = true;
        }
      }
    }
  
    if (rule["required"] == true && rule["min"] != null && value < rule["min"]) {
      errors[field] = rule["minMessage"] || `Value must be greater than or equal to ${rule["min"]}`;
      isError = true;
    }
  
    if (rule["required"] == true && rule["max"] != null && value > rule["max"]) {
      errors[field] = rule["maxMessage"] || `Value must be less than or equal to ${rule["max"]}`;
      isError = true;
    }

    if (rule.type == "arrayInline") {
      if (rule["required"] == true) {
        value = removeEmptyObjectFromArray(value);
        if (!value || !value.length) {
          errors[field] = { _error: rule["message"] || "Please select at least 1 of these" };
          isError = true;
        } else {
          const subFields = rule.subFields;
          if (subFields) {
            const arrayErrors = [];
            value && value.map((subValue, index) => {
              const subErrors = {};
              subFields.map((sub) => {
                const { name, type, rule } = sub;
                if (type && type == "number") {
                  if (sub.min != null && subValue[name] < sub.min) {
                    subErrors[name] = sub["minMessage"] || `Value must be greater than ${subValue[name]}`;
                  }
                  if (sub.max != null && subValue[name] > sub.max) {
                    subErrors[name] = sub["maxMessage"] || `Value must be less than ${subValue[name]} or equal to ${sub.max}`;
                  }
                  if (subValue[name] == null) {
                    subErrors[name] = "This field is required";
                  }
                } else if (type && type == "object") {
                  let newRule = rule;
                  if (typeof rule == "function") {
                    newRule = rule(subValue);
                  }
                  subErrors[name] = validate(newRule, subValue[name]);
                  if (subErrors[name] == null) {
                    delete subErrors[name];
                  }
                }
                else {
                  if (!subValue || !subValue[name]) {
                    subErrors[name] = "This field is required";
                  }
                }
                arrayErrors[index] = subErrors;
              });
              if (removeEmptyObjectFromArray(arrayErrors).length) {
                errors[field] = arrayErrors;
                isError = true;
              } else {
                if (rule.validation) {
                  const validation = rule.validation(value);
                  if (validation) {
                    errors[field] = { _error: validation.message };
                    isError = true;
                  }
                }
              }
            });
          }
        }
      } else {
        const subFields = rule.subFields;
        value = removeEmptyObjectFromArray(value);
        if (subFields) {
          const arrayErrors = [];
          value && value.map((subValue, index) => {
            const subErrors = {};
            subFields.map((sub) => {
              const { name, type } = sub;
              if (type && type == "number") {
                if (sub.min != null && subValue[name] < sub.min) {
                  subErrors[name] = sub["minMessage"] || `Value must be greater than ${subValue[name]}`;
                }
                if (sub.max != null && subValue[name] > sub.max) {
                  subErrors[name] = sub["maxMessage"] || `Value must be less than ${subValue[name]} or equal to ${sub.max}`;
                }
                if (subValue[name] == null) {
                  subErrors[name] = "This field is required";
                }
              } else if (type && typeof(type) == "function") {
                if (type(subValue)) {
                  subErrors[name] = "This field is required";
                }
              }
              else {
                if (!subValue || !subValue[name]) {
                  subErrors[name] = "This field is required";
                  arrayErrors[index] = subErrors;
                }
              }
              arrayErrors[index] = subErrors;
            });
            if (removeEmptyObjectFromArray(arrayErrors).length) {
              errors[field] = arrayErrors;
              isError = true;
            } else {
              if (rule.validation) {
                const validation = rule.validation(value);
                if (validation) {
                  errors[field] = { _error: validation.message };
                  isError = true;
                }
              }
            }
          });
        }
      }
    }

    if (rule.type == "arrayInlineCustomField") {
      const arrayErrors = [];
      const subErrors = {};
      const subFields = rule.subFields;
      subFields.map((sub, index) => {
        const { name, required, inputKey } = sub;
        if (required == true && value[index][name] == null) {
          subErrors[name] = "This field is required";
          arrayErrors[inputKey] = subErrors;
        }
      });
      if (arrayErrors.length > 0) {
        errors[field] = arrayErrors;
        isError = true;
      }
    }
  }
  return isError ? errors : null;
};

export const createSubmitAction = (
  rules, 
  action,
  beforeSubmit,
  shouldDispatch = true,
  beforeSubmissionError,
) => (values, dispatch) => {
  let error = null;
  error = validate(rules, values);
  if (error != null) {
    if (beforeSubmissionError) {
      beforeSubmissionError(values);
    }
    throw new SubmissionError(error);
  } else {
    let newValues = values;
    if (beforeSubmit) {
      newValues = beforeSubmit(values);
    }
    if (!shouldDispatch) {
      return action(newValues);
    }
    return dispatch(action(newValues));
  }
};

export const renderFileList = (fileList) => {
  fileList && fileList.map((value) => {
    const {
      id,
      url,
    } = value;
    value.uid = id;
    value.url = url;
    value.thumbUrl = url;
    return value;
  });
  return fileList;
};

export const combineLocale = (locale, rootKey) => {
  const keys = Object.keys(locale);
  let result = {};
  for (const key of keys) {
    const value = locale[key];
    const mainKey = rootKey != null ? `${rootKey}.${key}` : `${key}`;
    if (typeof value == "object") {
      const nestedResult = combineLocale(value, mainKey);
      result = {
        ...result,
        ...nestedResult,
      };
    } else {
      result[mainKey] = locale[key]; 
    }
  }
  return result;
};

export function getQueryParamUrl() {
  const query = QueryString.parse(location.search, {
    arrayFormat: "bracket"
  });
  return query;
}

export function getQueryParamUrlWithProps(props) {
  const query = QueryString.parse(props.location.search, {
    arrayFormat: "bracket"
  });
  return query;
}

export function updateQueryParamUrl(query, history) {
  history.push({
    search: QueryString.stringify(query, { arrayFormat: "bracket" })
  });
}

export function getQueryParamFromLocalStorage() {
  const activeFilter = localStorage.getItem("FILTER_ACTIVE");
  const value = localStorage.getItem(activeFilter);
  const query = value != null ? JSON.parse(value) : {};
  return query;
}

export function updateQueryParamToLocalStorage(query) {
  const activeFilter = localStorage.getItem("FILTER_ACTIVE");
  localStorage.setItem(activeFilter, JSON.stringify(query));
}


export const getPageNumber = (props) => {
  const params = QueryString.parse(props.location.search);
  let page = 0;
  const reg = /^\d+$/;
  if (params != null && params.page != null && params.page >= 0) {
    if(params.page.match(reg)) {
      page = params.page;
      if (page >= 1) {
        page = page - 1;
      }
      return parseInt(page);
    }else {
      return false;
    }
  }
};

export const getSortOption = (location) => {
  const params = QueryString.parse(location.search);
  return {
    sortBy: params.sortBy,
    order: params.order,
  };
};

export const getParamFilter = (props, keys) => {
  let query = getQueryParamUrlWithProps(props);
  const filter = {};
  keys && keys.map(key => {
    const value = query[key];
    if (value) {
      filter[key] = query[key];
    }
  });
  return filter;
};

export const getSortKeys = (location) => {
  const keys = ["order", "sortBy"];
  let query = QueryString.parse(location.search, {arrayFormat: "bracket"});
  const sort = {};
  keys && keys.map(key => {
    const value = query[key];
    if (value) {
      sort[key] = query[key];
    }
  });
  return sort;
};

export const generateQuery = (value) => {
  let query = QueryString.parse(location.search, {arrayFormat: "bracket"});
  query["page"] = value;
  const page = QueryString.stringify(query, { arrayFormat: "bracket" });
  return page;
};

export const getParamLimit = (props) => {
  let query = getQueryParamUrlWithProps(props);
  const limit = query["limit"];
  const pageSize = ListLayoutConstants.LIST_LAYOUT_PAGE_SIZE;
  const isPageSizeDefined = _.indexOf(pageSize, limit);
  if(isPageSizeDefined == -1) {
    return ListLayoutConstants.LIST_LAYOUT_DEFAULT_PAGE_SIZE;
  } else {
    return limit;
  }
};

export function modalConfirmDelete(id, entity, callback, value, desc) {
  const { title, content, okText, cancelText } = desc;
  confirm({
    title: title || `Do you want to delete this ${entity} ?`,
    content: content || "Once you delete it, it cannot be undone.",
    okText: okText || "Delete",
    cancelText: cancelText || "Cancel",
    okType: "danger",
    width: "450px",
    onOk() {
      if (value) {
        return Promise.resolve(callback(value));
      }
      return Promise.resolve(callback(id));
    }
  });
}

export function modalConfirmArchive(id, entity, callback, onFinish, desc) {
  const { title, content, okText, cancelText } = desc;
  confirm({
    title: title || `Do you want to archive this ${entity} ?`,
    content: content || "Once you archive it, it will disappear from list.",
    okText: okText || "Archive",
    cancelText: cancelText || "Cancel",
    width: "450px",
    onOk() {
      return Promise.resolve(callback(id)).then(() => {
        if (onFinish) {
          onFinish();
        }
      });
    }
  });
}

export function modalConfirmUnarchive(id, entity, callback, onFinish, desc) {
  const { title, content, okText, cancelText } = desc;
  confirm({
    title: title || `Do you want to unarchive this ${entity} ?`,
    content: content || "Once you unarchive it, it will appear on the list.",
    okText: okText || "Unarchive",
    cancelText: cancelText || "Cancel",
    okType: "primary",
    width: "450px",
    onOk() {
      return Promise.resolve(callback(id)).then(() => {
        if (onFinish) {
          onFinish();
        }
      });
    }
  });
}

export function getBreadcrumbPageLabel(entity) {
  const name = entity && entity.match(/[A-Z][a-z]+/g);
  if(name && name.length > 1) {
    const page = name.join(" ");
    return page;
  }
  return entity;
}

export function getPathName(path, length) {
  const rootPaths = [...path];
  rootPaths.splice(0, 1);
  rootPaths.splice(path.length - length, 1);
  return rootPaths.join("/");
}

export function getMainPath(path) {
  const rootPaths = [...path];
  rootPaths.splice(0, 0);
  rootPaths.splice(path.length - 2, 2);
  return rootPaths.join("/");
}

export function modalConfirmDialog(title, content, callback, values, width, okType, onFinish) {
  return confirm({
    title: `Are you sure to ${title} ?`,
    content: content,
    okText: "Yes",
    okType: okType || "danger",
    width: width || "470px",
    onOk() {
      return Promise.resolve(callback(values)).then(() => {
        if (onFinish) {
          onFinish();
        }
      }).catch((error) => {
        onFinish(error);
      });
    },
  });
}

export function toRelative(value) {
  const relative = moment(value, "").fromNow();
  return relative;
}

export function toDate(value) {
  if (value) {
    const date = moment(value).format(DefaultConstants.DEFAULT_DISPLAY_DATE_TIME_FORMAT);
    return date;
  }
  return null;
}

export function toShortDate(value) {
  if (value) {
    const date = moment(value).format(DefaultConstants.DEFAULT_DISPLAY_DATE_FORMAT);
    return date;
  }
  return null;
}

export function toTime(value) {
  if (value) {
    const time = moment(value, DefaultConstants.DEFAULT_TIME_FORMAT).format(DefaultConstants.DEFAULT_DISPLAY_TIME_FORMAT);
    return time;
  }
  return null;
}

export function toQueryParamDate(value) {
  const date = moment(value).format(DefaultConstants.DEFAULT_ASYNC_DATE_FORMAT);
  return date;
}

export function toQueryParamMonth(value) {
  const month = moment(value).format(DefaultConstants.DEFAULT_ASYNC_MONTH_FORMAT);
  return month;
}

export function toQueryParamYear(value) {
  const month = moment(value).format(DefaultConstants.DEFAULT_DISPLAY_YEAR_FORMAT);
  return month;
}

export function validatePhoneNumber(value) {
  const valid = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/g; //eslint-disable-line
  return valid.test(value);
} 

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function convertLocationParams(props) {
  const { location: { search } } = props;
  let paramQuery = search;
  paramQuery = search.slice(1);
  paramQuery = paramQuery.split("=");
  const paramName = paramQuery && paramQuery[0];
  const paramValue = paramQuery && Number(paramQuery[1]);
  return {
    paramName,
    paramValue,
  };
}

export function renderIdsValues(name, isSingle) {
  let values = [];
  const query = getQueryParamUrl();
  const valueFormUrl = query[name];
  if (isSingle) {
    values = [Number(valueFormUrl)];
  } else {
    if (valueFormUrl && valueFormUrl.length > 0) {
      values = valueFormUrl.map(value => Number(value));
    }
  }
  return values;
}

export function queryParamsDate(values, format, toFormat) {
  const date = moment(values, format || DefaultConstants.DEFAULT_ASYNC_DATE_FORMAT);
  return date.format(toFormat || DefaultConstants.DEFAULT_ASYNC_DATE_FORMAT);
}

export function convertFieldToDisplay(field) {
  let value = field.replace(/([A-Z])/g, " $1");
  const firstCharacter = value[0];
  value = value.replace(/^./, firstCharacter.toUpperCase());
  return value;
}

export function getDayLabel(numberOfDay) {
  const dayLabel = numberOfDay > 1 ? "days" : "day";
  return `${numberOfDay} ${dayLabel}`;
}

export function getLabelWithFirstOfString(string) {
  string = string.toLowerCase();
  string = capitalizeFirstLetter(string);
  let newString = getBreadcrumbPageLabel(string);
  newString = newString.split(" ");
  newString = newString.map((value) => value.charAt(0));
  newString = newString.join((""));
  return newString.toUpperCase();
}

export function renderDisplayTextFromTextArea(texts) {
  if (texts) {
    return (
      <div className="editor-text " dangerouslySetInnerHTML={{ __html: texts.replace(/\n/g, "<br />") }}></div>
    );
  }
  return null;
}

export function getHourLabel(numberOfHour) {
  if (numberOfHour != null) {
    const hourLabel = numberOfHour > 1 ? "hours" : "hour";
    return `${numberOfHour} ${hourLabel}`;
  }
  return null;
}

export function getMinuteLabel(numberOfMinute) {
  if (numberOfMinute) {
    const minuteLabel = numberOfMinute > 1 ? "minutes" : "minute";
    return `${numberOfMinute} ${minuteLabel}`;
  }
}

export function getMonthLabel(numberOfMonth) {
  const monthLabel = `${numberOfMonth} ${numberOfMonth > 1 ? "months" : "month"}`;
  return monthLabel;
}

export const validateValueTypeUrl = (value) => {
  const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/; //eslint-disable-line
  return regexp.test(value);
};

export const getDateList = () => { 
  const postfixes = { 
    1: "st", 
    2: "nd", 
    3: "rd", 
    21: "st", 
    22: "nd", 
    23: "rd", 
    31: "st", 
  }; 
  const dateList = []; 
  for (let i = 0; i < 31; i++) { 
    let name = `${i+1}th`; 
    const postfix = postfixes[i+1]; 
    if (postfix) { 
      name = `${i+1}${postfix}`; 
    } 
    dateList.push({
      id: i+1,
      name
    }); 
  } 
  return dateList; 
};

export const getDefaultCurrentDateRange = (values, startKey="from", endKey="to", type="day") => {
  const {
    filter,
    filter: {
      dateType,
    }
  } = values;
  const start = filter[startKey];
  const end = filter[endKey];
  if (start == null && end == null  && dateType == null) {
    values.filter[startKey] = toQueryParamDate(moment().startOf(type));
    values.filter[endKey] = toQueryParamDate(moment().endOf(type));
  } 
  return values;
};

export const getShortNameWithTooltip = (text, textLength=75, placement="right") => {
  const trimmedText = text.length > textLength ? text.substring(0, textLength - 3) + "..." : text;
  return (
    <Tooltip placement={placement} title={text}>
      {trimmedText}
    </Tooltip>
  );
};

export function getPageNumberLabel(numberOfPage) {
  const pageNumberLabel = `${numberOfPage} ${numberOfPage > 1 ? "pages" : "page"}`;
  return pageNumberLabel;
}