import React, { useState, useEffect } from "react";
import { Checkbox } from "antd";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

// Helper
import { 
  getQueryParamUrl,
  updateQueryParamUrl,
  getQueryParamFromLocalStorage,
  updateQueryParamToLocalStorage,
} from "@/helpers";

function AsyncCheckBox(props) {
  const { queryStorage, name, history, location } = props;
  const query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
  const [check, setCheck] = useState(query[name]);
  useEffect(() => {
    setCheck(Boolean(query[name]));
  }, [location]);

  const onChange = (event) => {
    const { 
      value, 
      onChange, 
      isNumber, 
    } = props;
    const { target: { checked } } = event; 
    setCheck(checked);
    let query = queryStorage == "url" ? getQueryParamUrl() : getQueryParamFromLocalStorage();
    const page = query.page;
    if (page && page > 1) {
      delete query.page;
    }
    if (checked) {
      if (value) {
        query[name] = isNumber ? Number(value) : value;
      } else {
        query[name] = isNumber ? Number(checked) : checked;
      }
    } else {
      delete query[name];
    }
    if (queryStorage == "url"){
      updateQueryParamUrl(query, history);
    } else {
      updateQueryParamToLocalStorage(query);
    }
    if (onChange) {
      onChange(checked);
    }
  };

  const { label } = props;
  return (
    <Checkbox 
      checked={check}
      onChange={onChange}
    >
      {label}
    </Checkbox>
  );
}

AsyncCheckBox.propTypes = {
  value: PropTypes.any,
  name: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  location: PropTypes.any,
  isNumber: PropTypes.bool,
  history: PropTypes.object,
  queryStorage: PropTypes.string,
};

AsyncCheckBox.defaultProps = {
  queryStorage: "local"
};

export default withRouter(AsyncCheckBox);