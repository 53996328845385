import React from "react";
import { Button } from "antd";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { SearchOutlined  } from "@ant-design/icons";

// Helper
import { 
  updateQueryParamUrl, 
  getQueryParamFromLocalStorage, 
} from "@/helpers";

function AsyncSearchButton(props) {

  const onSearch = () => {
    const { history } = props;
    const query = getQueryParamFromLocalStorage();
    updateQueryParamUrl(query, history);
  };

  const { loading } = props;
  return (
    <Button
      ghost
      icon={<SearchOutlined  />}
      type="primary"
      loading={loading}
      disabled={loading}
      onClick={onSearch}
    >
      <span className="ml-1">
        {loading ? <FormattedMessage id="listLayout.loading" /> : <FormattedMessage id="listLayout.search" />}
      </span>
    </Button>
  );
}

AsyncSearchButton.propTypes = {
  loading: PropTypes.bool,
  onReset: PropTypes.func,
  history: PropTypes.object,
  filterKeys: PropTypes.array,
};

export default withRouter(AsyncSearchButton);