import React from "react";
import { Table } from "antd";
import PropTypes from "prop-types";

// services
import { getItemInStock } from "@/services/item";


function StockDetailBox(props) {

  const [loading, setLoading] = React.useState(false);
  const [dataSource, setDataSource] = React.useState([]);

  React.useEffect(() => {
    onGetStockDetail(props.id);
  }, [props.id]);

  const onGetStockDetail = (id) => {
    if (id != null) {
      setLoading(true);
      getItemInStock(id).then((response) => {
        if (response) {
          setDataSource(response);
          setLoading(false);
        }
      }).catch(() => {
        setDataSource([]);
        setLoading(false);
      });
    } else {
      setDataSource([]);
      setLoading(false);
    }
  };

  const columns = () => {
    return [
      {
        title: "Warehouse",
        dataIndex: "warehouse",
        render: (warehouse) => {
          if (warehouse) {
            return warehouse.name;
          }
          return null;
        }
      },
      {
        title: "Quantity",
        dataIndex: "quantity",
        render: (quantity) => {
          return (
            <div className="text-left">
              {quantity}
            </div>
          );
        }
      },
    ];
  };

  return (
    <Table 
      size="small"
      dataSource={dataSource} 
      pagination={false}
      columns={columns()} 
      loading={{
        tip: "Loading...",
        spinning: loading
      }}  
    />
  );
}


StockDetailBox.propTypes = {
  id: PropTypes.number,
};

export default StockDetailBox;