export default {
  "mainEntityName": "គ្រប់គ្រងអ្នកប្រើប្រាស់",
  "entityName": "គណនី",
  "name": "ឈ្មោះ",
  "loginId": "ឈ្មោះសំរាប់ចូលប្រព័ន្ធ",
  "emailAddress": "អុីមែ៉ល",
  "phoneNumber": "លេខទូរស័ព្ទ",
  "roles": "តួនាទី",
  "status": "ស្ថានភាព",
  "password": "លេខសំងាត់",
  "login": "ចូលប្រព័ន្ធ",
  "forgotPassword": "ភ្លេចលេខសំងាត់ ?",
  "credentials": "លំអិត",
  "changePassword": "ផ្លាស់ប្តូរលេខសំងាត់",
  "currentPassword": "លេខសំងាត់បច្ចុប្បន្ន",
  "newPassword": "លេខសំងាត់ថ្មី",
  "confirmPassword": "ផ្ទៀងផ្ទាត់លេខសំងាត់",
  "firstName": "នាមត្រកូល",
  "lastName": "នាមខ្លួន",
  "userEmailAsLoginId": "ប្រើអុីម៉ែលជាឈ្មោះសំរាប់ចូលប្រព័ន្ធ",
};
