import _ from "lodash";
import { denormalize } from "normalizr";
import combineLocale from "@/localization";
import { CurrentUserSchema } from "@/schemas/current-user";
import * as AuthenticationConstants from "@/constants/authentication";

export function getBasicToken() {
  const clientCredential = localStorage.getItem(AuthenticationConstants.CLIENT_CREDENTIAL_TYPE);
  if (clientCredential == null) {
    return null;
  }
  const serializedClientCredential = JSON.parse(clientCredential);
  return serializedClientCredential;
}

export function getAccessToken() {
  const clientCredential = localStorage.getItem(AuthenticationConstants.CLIENT_CREDENTIAL_TYPE);
  if (clientCredential == null) {
    return null;
  }
  const serializedClientCredential = JSON.parse(clientCredential);
  return serializedClientCredential;
}

export const getLoading = (state, requestName) => {
  const loading = state.request[requestName];
  return loading;
};

export const getError = (state, requestName) => {
  return state.error[requestName];
};

export const getAutoComplete = (state, requestName) => {
  const autoComplete = state.autoComplete[requestName];
  const dataSource = autoComplete == null ? null : autoComplete["list"];
  return dataSource;
};

export const getCurrentUser = (state) => {
  const { entities, currentUser } = state;
  const currentUserEntities = denormalize(
    currentUser,
    CurrentUserSchema,
    entities
  );
  return currentUserEntities && currentUserEntities;
};

export const getCurrentRoles = (state) => {
  const { entities, currentUser } = state;
  const currentUserEntities = denormalize(
    currentUser,
    CurrentUserSchema,
    entities
  );
  return currentUserEntities && currentUserEntities.roles;
};

export const getNavBarDisplayAllowed = (state, subMenu) => {
  const currentUserRole = getCurrentRoles(state);
  let displayNavBar = false;
  for(const userRoles of currentUserRole) {
    const permissions = userRoles.permissions;
    const permissionCode = permissions.map((value) => Number(value.code));
    subMenu.map((sub) => {
      if (_.includes(permissionCode, sub)) {
        displayNavBar = true;
      }
    });
  }
  return displayNavBar;
};

export const getPermissionAllowed = (state, permissionCode) => {
  const currentUserRole = getCurrentRoles(state);
  if (currentUserRole && currentUserRole.length > 0) {
    let authorized = false;
    for(const userRoles of currentUserRole) {
      const permissions = userRoles.permissions;
      const isAuthorized = permissions && permissions.find(value => value.code == permissionCode);
      if (isAuthorized) {
        authorized = true;
      }
    }
    return authorized;
  }
};

export const getLocalizedString = (state, key) => {
  const { locale: { locale } } =  state;
  return combineLocale[locale] && combineLocale[locale][key];
};

export const getPageTitle = (state, page) => {
  document.title = page;
};

export const renderMenu = (state, menus) => {
  const allowedMenus = [];
  menus.map((menuItem) => {
    const { sub: subMenus } = menuItem;
    if (subMenus) {
      const allowedSubMenus = subMenus.filter(subMenu => {
        const { code, unChecked } = subMenu;
        const isAllowed = getPermissionAllowed(state, code);
        return unChecked ? true : isAllowed == true ? subMenu : null;
      });
      if (allowedSubMenus.length > 0) {
        allowedMenus.push({
          ...menuItem,
          sub: allowedSubMenus
        });
      }
    } else {
      const { code, unChecked } = menuItem;
      const isAllowed = unChecked ? true : getPermissionAllowed(state, code);
      if (isAllowed) {
        allowedMenus.push({
          ...menuItem
        });
      }
    }
  });
  return allowedMenus;
};