export const DEFAULT_DISPLAY_DATE_FORMAT = "DD/MM/YYYY";
export const DEFAULT_DISPLAY_DATE_AND_MONTH_FORMAT = "DD/MM";
export const DEFAULT_ASYNC_DATE_FORMAT = "DD-MM-YYYY";
export const DEFAULT_DISPLAY_MONTH_FORMAT = "MMM";
export const DEFAULT_ASYNC_MONTH_FORMAT = "MM";
export const DEFAULT_DISPLAY_YEAR_FORMAT = "YYYY";
export const DEFAULT_DISPLAY_MONTH_YEAR_FORMAT = "MMM-YYYY";
export const DEFAULT_ASYNC_MONTH_YEAR_FORMAT = "MM-YYYY";
export const DEFAULT_DISPLAY_DATE_TIME_FORMAT = "DD/MM/YYYY hh:mm a";
export const DEFAULT_DISPLAY_DATE_AND_TIME_FORMAT = "DD-MM-YYYY hh:mm a";
export const DEFAULT_DISPLAY_TIME_FORMAT = "hh:mm a";
export const DEFAULT_TIME_FORMAT = "HH:mm:ss";
export const DEFAULT_CURRENCY_FORMAT = "0,0.00";
export const DEFAULT_YEAR_AND_DATE_FORMAT = "YYYY-MM-DD";
export const CURRENCY_DEFAULT_NUMBER_FORMAT = "0,0.00";
export const DEFAULT_MOMENT_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSSSZ";