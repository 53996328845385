export default {
  "edit": "កែប្រែ",
  "date": "កាលបរិច្ឆេទ",
  "create": "បង្កើតថ្មី",
  "delete": "លុប",
  "archive": "លុបបណ្ណោះអាសន្ន",
  "loading": "កំពុងដំណេីរការ...",
  "action": "មុខងារ",
  "print": "ព្រីន",
  "back": "ត្រលប់",
  "unarchive": "បង្ហាញឡើងវិញ",
  "duplicate": "ចំលង",
  "creator": "បង្កើតដោយ",
  "createdAt": "បង្កើតនៅ",
  "updatedAt": "កែប្រែនៅ",
  "cancel": "បោះបង់",
  "people": "បង្កើត",
  "deleteMessage": "តើអ្នកពិតជាចង់លុបទិន្នន័យនេះ ?",
  "deleteMessageDescription": "ទិន្នន័យនឹងបាត់ពីប្រព័ន្ធបន្ទាប់ពីលុប",
  "archiveMessage": "តើអ្នកពិតជាចង់លុបទិន្នន័យនេះបណ្ណោះអាសន្ន ?",
  "archiveMessageDescription": "ទិន្នន័យនឹងបាត់ពីប្រព័ន្ធបណ្ណោះអាសន្ន",
  "unarchiveMessage": "តើអ្នកពិតជាចង់បង្ហាញទិន្នន័យនេះឡើងវិញ ?",
  "unarchiveMessageDescription": "ទិន្នន័យនឹងបង្ហាញឡើងវិញ",
};