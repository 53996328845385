import React from "react";
import axios from "axios";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// Component
import App from "./views";

// style
import "./styles/index.less";

// store
import configureStore from "@/stores";
import { setLocale } from "@/actions/locale";

const store = configureStore();

if (localStorage.lang) {
  store.dispatch((setLocale(localStorage.lang)));
}

store.subscribe(() => {
  const state = store.getState();
  let token = state.accessToken;
  if (token && token.user) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.user.accessToken}`;
  } else {
    axios.defaults.headers.common["Authorization"] = null;
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);