// Libs
import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";
import styled from "styled-components";
import { LoadingOutlined } from "@ant-design/icons";

const Wrapper = styled.div`
  .anticon-loading {
    font-size: 7.5vh;
    color: #335e9c !important;
  }

  h3 {
    color: #335e9c !important;
  }

  h5 {
    color: #335e9c !important;
  }


  @media (max-width: 1024px) {
    i {
      font-size: 5vh;
      color: #335e9c !important;
    }

    h3 {
      font-size: 1.25rem;
    }

    h5 {
      font-size: 1rem;
      color: #335e9c !important;
    }
  }
`;

function Loading(props) {

  const { header, text } = props;
  return (
    <Container style={{ height: "100vh" }} className="d-flex justify-content-center align-items-center">
      <Wrapper className="col-12 text-center">
        <LoadingOutlined />
        <h3 className="my-3">{header}</h3>
        <h5 className="text-mute">{text}</h5>
      </Wrapper>
    </Container>
  );
}

Loading.propTypes = {
  header: PropTypes.string,
  text: PropTypes.string,
  afterDelay: PropTypes.number
};

export default Loading;