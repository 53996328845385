import React, { Fragment, useState } from "react";
import { Avatar, Button, Modal } from "antd";
import PropTypes from "prop-types";
import { getItemList } from "@/actions/item";
import { ItemArraySchema } from "@/schemas/item";
import { ITEM_LIST_REQUEST } from "@/constants/item";
import ListLayout from "@/components/layout/list-layout";
import NoImage from "@/images/background/noImage.png";
import { AppstoreOutlined } from "@ant-design/icons";
import StockDetailBox from "@/components/stock-detail-box";
import FilterComponent from "./filter-box";

const ItemList = () => {

  const [visible, setVisible] = useState(false);
  const [id, setId] = useState(null);
  const [title, setTitle] = useState("");


  const columns = () => {
    return [
      {
        title: "Name",
        sortable: true,
        sortKey: "name",
        dataIndex: "name",
        width: "50%",
        render: (title, value) => {
          const { name, imageUrl } = value;
          return (
            <Fragment>
              <Avatar shape="square" src={imageUrl ? imageUrl : NoImage} /> {name}
            </Fragment>
          );
        }
      },
      {
        title: "UPC",
        dataIndex: "barcode",
        width: "15%",
      },
      {
        title: "Part Number / SKU",
        dataIndex: "partNumber",
        width: "15%",
      },
      {
        title: "Quantity",
        sortable: true,
        sortKey: "quantity",
        dataIndex: "quantity",
        width: "10%",
      },
      {
        title: "View Stock",
        dataIndex: "id",
        width: "10%",
        render: (_, row) => {
          return (
            <Button 
              size="small" 
              type="primary"
              icon={<AppstoreOutlined />}
              disabled={row.quantity == 0}
              onClick={() => {
                setId(row.id);    
                setVisible(true);
                setTitle(row.name);
              }}
            >
              View
            </Button>
          );
        }
      }
    ];
  };

  return(
    <Fragment>
      <ListLayout 
        title="Item List"
        action={getItemList}
        reducerName="item"
        columns={columns()}
        schema={ItemArraySchema}
        filterComponent={FilterComponent}
        requestName={ITEM_LIST_REQUEST}
        filterKeys={["includeOutOfStock"]}
      />
      <Modal
        title={title}
        width="800px"
        footer={null}
        visible={visible}
        maskClosable={false}
        onCancel={() => {
          setVisible(false);
          setId(null);
          setTitle("");
        }}
      >
        <StockDetailBox id={id} />
      </Modal>
    </Fragment>
  );
};

ItemList.propTypes = {
  match: PropTypes.object,
  permission: PropTypes.object,
};

export default ItemList;