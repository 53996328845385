export default {
  "edit": "Edit",
  "date": "Dates",
  "create": "Create",
  "delete": "Delete",
  "archive": "Archive",
  "unarchive": "Unarchive",
  "loading": "Loading...",
  "back": "Back",
  "action": "Actions",
  "print": "Print",
  "duplicate": "Duplicate",
  "creator": "Creator",
  "createdAt": "Created At",
  "updatedAt": "Updated At",
  "save": "Save",
  "cancel": "Cancel",
  "people": "People",
  "deleteMessage": "Do you want to delete this ?",
  "deleteMessageDescription": "Once you delete it, it cannot be undone.",
  "archiveMessage": "Do you want to archive this ?",
  "archiveMessageDescription": "Once you archive it, it will disappear from list.",
  "unarchiveMessage": "Do you want to unarchive this ?",
  "unarchiveMessageDescription": "Once you Unarchive it, it will appear on the list.",
};