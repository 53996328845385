import React from "react";
import PropTypes from "prop-types";
import { Typography } from "antd";
import { Link, withRouter } from "react-router-dom";

function BreadCrumbDynamic(props) {

  const { location: { pathname }, breadcrumbs } = props;
  const paths = pathname.split("/");
  const lastBreadcrumb = breadcrumbs.splice(breadcrumbs.length - 1, 1);
  return (
    <Typography.Title level={4} className="mb-0">
      {breadcrumbs && breadcrumbs.map((breadcrumb, index) => {
        const fullPaths = [
          ...paths
        ];
        const spliceIndex = paths.length - breadcrumbs.length + index;
        const spliceLength = breadcrumbs.length - index;
        fullPaths.splice(spliceIndex, spliceLength);
        return (
          <span key={index}>
            <Link to={fullPaths.join("/")}>
              {breadcrumb}
            </Link>
            {" / "}
          </span>
        );
      })
      }
      {lastBreadcrumb}
    </Typography.Title>
  );
}

BreadCrumbDynamic.propTypes = {
  breadcrumbs: PropTypes.array,
  location: PropTypes.object,
};

export default withRouter(BreadCrumbDynamic);