import moment from "moment";

// Actions
import * as service from "@/services/authentication";
import { createEditAction } from "@/utils/action-creator";
import * as AuthenticationConstants from "@/constants/authentication";
import { DEFAULT_DISPLAY_DATE_TIME_FORMAT } from "@/constants/default";

export const authenticateInit = (values, successor) => (dispatch, getState) => {

  const onSuccess = (clientCredential) => {
    const localClientCredential = localStorage.getItem(
      AuthenticationConstants.CLIENT_CREDENTIAL_TYPE
    );
    if (localClientCredential == null) {
      localStorage.setItem(
        AuthenticationConstants.CLIENT_CREDENTIAL_TYPE,
        JSON.stringify(clientCredential)
      );
      if (successor) {
        successor();
      }
    } else {
      const { expiresIn } = localClientCredential;
      const momentExpireDate = moment(expiresIn).format(DEFAULT_DISPLAY_DATE_TIME_FORMAT);
      const expireDate = moment(momentExpireDate);
      const now = moment().format(DEFAULT_DISPLAY_DATE_TIME_FORMAT);
      const currentDate = moment(now);
      const isExpired = expireDate.isBefore(currentDate);
      if (isExpired) {
        localStorage.removeItem(AuthenticationConstants.CLIENT_CREDENTIAL_TYPE);
        localStorage.setItem(
          AuthenticationConstants.CLIENT_CREDENTIAL_TYPE,
          JSON.stringify(clientCredential)
        );
      }
      if (successor) {
        successor();
      }
    }
  };

  const option = {
    dispatch,
    getState,
    requestName: AuthenticationConstants.CLIENT_CREDENTIAL_REQUEST,
    serviceMethod: service.authenticateInit,
    onSuccess: onSuccess,
    showErrorMessage: false,
  }; 
  return createEditAction(option, values);
};