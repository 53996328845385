import * as ErrorConstants from "@/constants/error";

const initialState = {};

export default function(state = initialState, action) {
  switch (action.type) {
  case ErrorConstants.SET_ERROR:
    return setError(state, action);
  }
  return state;
}

function setError(state, action) {
  const { error, requestType } = action;
  const requestObject = {};
  requestObject[requestType] = error;
  return Object.assign({}, state, requestObject);
}