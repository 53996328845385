export default {
  "mainEntityName": "User Management",
  "entityName": "User Account",
  "name": "Name",
  "loginId": "Username",
  "emailAddress": "Email Address",
  "phoneNumber": "Phone Number",
  "roles": "Roles",
  "status": "Status",
  "password": "Password",
  "login": "Login",
  "forgotPassword": "Forgot Password ?",
  "credentials": "Credentials",
  "changePassword": "Change Password",
  "currentPassword": "Current Password",
  "newPassword": "New Password",
  "confirmPassword": "Confirm Password",
  "firstName": "First Name",
  "lastName": "Last Name",
  "userEmailAsLoginId": "Use email as login ID",
};
