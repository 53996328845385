import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";

// Actions
import error from "./error";
import locale from "./locale";
import request from "./request";
import entities from "./entities";
import pagination from "./pagination";
import autoComplete from "./auto-complete";

// Current User
import item from "./item";

export default combineReducers({
  form: formReducer,
  item,
  error,
  locale,
  request,
  entities,
  pagination,
  autoComplete,
  
});