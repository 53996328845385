import React, { Fragment, useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { denormalize } from "normalizr";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import { Card, Table, Spin, Row, Col } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

// Components
import Header from "./header";
import Counter from "./counter";
import FilterBoxComponent from "@/components/filter-box";

// Helpers
import { 
  getSortKeys,
  getParamLimit,
  getPageNumber,
  getParamFilter,
  getQueryParamUrl, 
  updateQueryParamUrl,
} from "@/helpers";
import { 
  getPageTitle,
  getPermissionAllowed, 
} from "@/utils/value-selector";


const defaultFilterKeys = ["query", "archive"];

const WrapperCard = styled(Card)`
  .ant-card-head-title {
    padding: 0;
  }
`;

function ListLayout (props) {

  const { history, location } = props;

  useEffect(() => {
    const { requestName, getReload } = props;
    if (getReload) {
      getReload(handleReload);
    }
    localStorage.setItem("FILTER_ACTIVE", requestName);
    let query = getQueryParamUrl();
    localStorage.setItem(requestName, JSON.stringify(query));
    return () => {
      const { requestName } = props;
      localStorage.removeItem("FILTER_ACTIVE");
      localStorage.removeItem(requestName);
    };
  }, []);

  useEffect(() => {
    loadData(props);
  }, [location]);

  const loadData = (props) => {
    const { filterKeys, location, loadData, extraFilter } = props;
    const newFilterKeys = defaultFilterKeys.concat(filterKeys);
    const page = getPageNumber(props);
    let filter = getParamFilter(props, newFilterKeys);
    const sort = getSortKeys(location);
    const limit = getParamLimit(props);
    if (extraFilter) {
      filter = Object.assign(filter, extraFilter);
    }
    const values = {
      page,
      limit,
      sort,
      filter,
    };
    loadData(values);
  };

  const onChange = (pagination, filters, sorter) => {
    let query = getQueryParamUrl();
    let order = sorter.order;
    if (sorter.order == "ascend") {
      order = "ASC";
    }
    if (sorter.order == "descend") {
      order = "DESC";
    }
    if (order) {
      query["order"] = order;
      query["sortBy"] = sorter.field;
    } else {
      delete query.order;
      delete query.sortBy;
    }
    updateQueryParamUrl(query, history);
  };

  const columns = () => {
    let { columns, hasReloadInColumn } = props;
    let query = getQueryParamUrl();
    if (hasReloadInColumn) {
      columns = columns(handleReload);
    }
    return columns.map(column => {
      if (column.sortable == true) {
        column.sorter = true;
        column.sortOrder = false;
        if (query.sortBy == (column.sortKey || column.dataIndex)) {
          let order = query["order"];
          if (order == "ASC") {
            order = "ascend";
          }
          if (order == "DESC") {
            order = "descend";
          }
          column.sortOrder = order;
        }
      }
      return column;
    });
  };

  const handlePaginationChange = (value) => {
    let query = getQueryParamUrl();
    query["page"] = value;
    updateQueryParamUrl(query, history);
  };

  const handlePageSizeChange = (current, pageSize) => {
    let query = getQueryParamUrl();
    query["limit"] = pageSize;
    updateQueryParamUrl(query, history);
  };

  const handleReload = () => {
    loadData(props);
  };

  const renderFilterBoxComponent = () => {
    const { 
      loading,
      filterKeys,
      enableArchiveFilter,
      filterComponent: FilterComponent,
    } = props;
    const newFilterKeys = defaultFilterKeys.concat(filterKeys);
    let filter = (
      <FilterBoxComponent 
        loading={loading}
        filterKeys={newFilterKeys}
        enableArchiveFilter={enableArchiveFilter}
      />
    );
    if (FilterComponent) {
      filter = (
        <FilterComponent 
          loading={loading} 
          filterKeys={newFilterKeys} 
        />
      );
    }
    return filter;
  };

  const renderTableComponent = (dataSource, defaultExpandAllRows) => {
    const { 
      tableSize, 
      loading, 
      rowKey, 
      childrenColumnName, 
      onExpand,
      rowSelection,
    } = props;
    return (
      <Table 
        size={tableSize}
        pagination={false}
        loading={{
          spinning: loading,
          indicator: <LoadingOutlined />,
          tip: <FormattedMessage id="listLayout.loading" />,
        }}
        rowKey={rowKey}
        columns={columns()} 
        onChange={onChange}
        onExpand={onExpand}
        dataSource={dataSource} 
        className="table-responsive"
        rowSelection={rowSelection}
        childrenColumnName={childrenColumnName}
        defaultExpandAllRows={defaultExpandAllRows}
      />
    );
  };

  const { 
    title,
    loading,
    entities,
    schema,
    objectIds,
    selected,
    creatable, 
    treeTable,
    pagination,
    hasSwitch,
    pageTitle,
    className,
    breadcrumbs,
    createAllowed,
    showPagination,
    newDataSource,
    extraButtons,
    match: { path }, 
    childrenColumnName,
    defaultExpandAllRows,
    switchComponent: SwitchComponent,
    extraFilterComponent: ExtraFilterComponent
  } = props;
  pageTitle(title);
  const page = getPageNumber(props);
  const defaultCurrent = page != null ? page + 1 : 1;
  let dataSource = denormalize(objectIds, schema, entities); 
  if (treeTable) {
    if (newDataSource) {
      dataSource = newDataSource(dataSource);
    } else {
      dataSource.map((objectEntity, index) => {
        objectEntity.key = index;
        if (childrenColumnName) {
          const newObjectEntity = objectEntity[childrenColumnName];
          newObjectEntity.map((childrenObjectEntity, childrenIndex) => {
            childrenObjectEntity.key = `${index}-${childrenIndex}`;
          });
        }
      });
    }
  } else {
    dataSource.map((objectEntity, index) => {
      objectEntity.key = index;
    });
  }
  return (
    <React.Fragment>
      <Header 
        path={path}
        title={title} 
        breadcrumbs={breadcrumbs}
        extraButtons={extraButtons}
        creatable={creatable && createAllowed}
      />
      <Row>
        <Col xxl={24} xl={24} lg={24} xs={24}>
          <WrapperCard 
            bordered={true} 
            className={className}
            bodyStyle={{padding: "16px"}}
            headStyle={{padding: "5px"}}
            title={
              <Fragment>
                {renderFilterBoxComponent()}
                {ExtraFilterComponent && (
                  <ExtraFilterComponent />
                )}
              </Fragment>
            }
          >
            {showPagination && (
              <Counter 
                className="mb-2"
                loading={loading}
                pagination={pagination}
                dataSource={dataSource}
                defaultCurrent={defaultCurrent}
                selected={selected}
                onClick={handleReload}
                handlePageSizeChange={handlePageSizeChange}
                handlePaginationChange={handlePaginationChange}
              />
            )}
            {hasSwitch ? (
              SwitchComponent && (
                <SwitchComponent 
                  loading={loading}
                  dataSource={dataSource}
                />
              )
            ) : defaultExpandAllRows && treeTable ? (
              <Spin 
                spinning={loading}
                className="d-block"
                indicator={<LoadingOutlined />} 
                tip={<FormattedMessage id="listLayout.loading" />}
              >
                {!loading && (
                  renderTableComponent(dataSource, true)
                )}
              </Spin>
            ) : (
              renderTableComponent(dataSource, false)
            )}
            {showPagination && (
              <Counter 
                className="mt-2"
                loading={loading}
                selected={selected}
                onClick={handleReload}
                pagination={pagination}
                dataSource={dataSource}
                defaultCurrent={defaultCurrent}
                handlePageSizeChange={handlePageSizeChange}
                handlePaginationChange={handlePaginationChange}
              />
            )}
          </WrapperCard>
        </Col>
      </Row>
    </React.Fragment>
  );
}

ListLayout.propTypes = {
  match: PropTypes.object,
  title: PropTypes.string,
  tableSize: PropTypes.string,
  columns: PropTypes.array,
  data: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  loadData: PropTypes.func,
  location: PropTypes.object,
  entities: PropTypes.object,
  schema: PropTypes.any,
  objectIds: PropTypes.array,
  filterKeys: PropTypes.array,
  enableArchiveFilter: PropTypes.bool,
  filterComponent: PropTypes.any,
  creatable: PropTypes.bool,
  createAllowed: PropTypes.bool,
  showPagination: PropTypes.bool,
  treeTable: PropTypes.bool,
  childrenColumnName: PropTypes.string,
  onExpand: PropTypes.any,
  defaultExpandAllRows: PropTypes.bool,
  rowKey: PropTypes.string,
  extraButtons: PropTypes.any,
  pageTitle: PropTypes.any,
  newDataSource: PropTypes.func,
  hasSwitch: PropTypes.bool,
  switchComponent: PropTypes.node,
  extraFilterComponent: PropTypes.node,
  hasReloadInColumn: PropTypes.bool,
  breadcrumbs: PropTypes.any,
  requestName: PropTypes.string,
  rowSelection: PropTypes.any,
  selected: PropTypes.number,
  getReload: PropTypes.number,
  history: PropTypes.object,
  extraFilter: PropTypes.any,
  className: PropTypes.string,
};

ListLayout.defaultProps = {
  loading: false,
  tableSize: "small",
  columns: [],
  dataSource: [],
  filterKeys: [],
  creatable: true,
  showPagination: true,
  treeTable: false,
  defaultExpandAllRows: false,
  rowKey: "key",
  hasSwitch: false,
  className: "m-3",
};

const mapStateToProps = (state, ownProps) => {
  const { requestName, reducerName, permission } = ownProps;
  const createCode = Number(permission && permission.code) + 1;
  return {
    entities: state.entities,
    objectIds: state[reducerName].list,
    loading: state.request[requestName],
    pagination: state.pagination[requestName],
    createAllowed: getPermissionAllowed(state, createCode),
    pageTitle: (page) => getPageTitle(state, page),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const { action } = ownProps;
  return {
    loadData: (values) => dispatch(action(values)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ListLayout));