import logger from "redux-logger";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

// Root
import rootReducer from "@/reducers";

const configureStore = (initialState) => {
  const middleWares = [];
  if (process.env.NODE_ENV !== "production") {
    middleWares.push(logger);
  }
  middleWares.push(thunk);
  const store = createStore(
    rootReducer,
    initialState,
    composeWithDevTools(applyMiddleware(...middleWares))
  );
  return store;
};

export default configureStore;