import { SET_LOCALE } from "@/constants/locale";

const initialState = {
  locale: "en"
};

export default function(state = initialState, action) {
  switch (action.type) {
  case SET_LOCALE:
    return {
      locale: action.locale
    };
  }
  return state;
}