import { GETList, GETWithToken } from "@/services/base-service";

const API_URL = "/api/item/v1";

export const getItem = (values) => {
  const url = `${API_URL}/list?includes[]=brand`;
  return GETList(url, values);
};

export function getItemInStock(id) {
  const url = `${API_URL}/${id}/stock/warehouse`;
  return GETWithToken(url);
}
