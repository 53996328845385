/* eslint-disable react/prop-types */
import React, { Fragment } from "react";
import { Result, Button } from "antd";
import { Link } from "react-router-dom";

function NotFound(props) {
  
  const { status, backTo } = props;
  return (
    <Result
      status={`${status}`}
      title={
        <Fragment>
          <div>
            <h3>{status}</h3>
          </div>
          {status == 404 ? `The ${name} you were looking for doesn't exist` : "Something went wrong !"}
        </Fragment>
      }
      extra={
        status == 404 ? (
          <Link to={backTo}>
            <Button 
              ghost
              icon="home"
              type="primary"
            >
              Back To {name} List
            </Button>
          </Link>
        ) : (
          <Button
            ghost
            icon="reload"
            type="primary"
            onClick={() => location.reload()}
          >
            Retry
          </Button>
        )}
    />
  );
}

export default NotFound;