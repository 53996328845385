import { POST } from "@/services/base-service";

const API_URL = "/api/oauth2/v1";

export const authenticateInit = (values) => {
  const { username, password } = values;
  const base64 = new Buffer(`${username}:${password}`).toString("base64");
  const token = `Basic ${base64}`;
  const headers = {
    Authorization: token
  };
  const body = {
    subDomain: process.env.REACT_APP_SUB_DOMAIN,
    appId: process.env.REACT_APP_APP_ID,
    appSecret: process.env.REACT_APP_APP_SECRET,
  };
  const url = `${API_URL}/init`;
  return POST(url, body, headers);
};
